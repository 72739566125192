import { createSelector } from "@reduxjs/toolkit";
import { AccessType } from "domain/accessRights/types";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import {
    hasCustomerSupport,
    hasAbsenceStatistics,
    hasAbsenceFollowup,
    hasCustomerCompanyAdministration,
    hasFileImportAdministration,
} from "utils/accessRights";
import { findAccessRightsBasedOnGivenRights, IAccessRightData, IAccessRightTypeEnum } from "domain/accessRights";
import { RootState } from "./index";
import { getActiveSearchCompanyId, getActiveSearchCustomerId } from "store/searchSelectors";

export const getUserSlice = (state: RootState) => state.user;

export const getUserContext = createSelector(getUserSlice, (user) => user.userContextExtended);
export const getUserStatus = createSelector(getUserSlice, (user) => user.status);

export const getUserAssume = createSelector(getUserSlice, (user) => user.assume);

export const getUserContextUser = createSelector(getUserContext, (userContextUser) => userContextUser?.user);

export const getUserCompanyEmployments = createSelector(
    getUserContext,
    (userContext) => userContext?.companyEmployment,
);

export const getUserAccount = createSelector(getUserContext, (userContext) => userContext?.userAccount);

export const getUserContactInfo = createSelector(getUserContextUser, (user) => user?.contactInformation);

export const getUserPreferences = createSelector(getUserContextUser, (user) => user?.userPreferences);

export const getUserCompanies = createSelector(
    getUserCompanyEmployments,
    (companyEmployments) => companyEmployments?.companies,
);

export const getUserCompanyAccessRights = createSelector(
    getUserAccount,
    (userAccount) => userAccount?.companiesAccessRights,
);
export const getUserAccessRights = createSelector(getUserAccount, (userAccount) => userAccount?.accessRights);
export const getUserAccessRightsNoUndefined = createSelector(
    getUserAccount,
    (userAccount) => userAccount?.accessRights ?? [],
);

export const getUserLanguage = createSelector(getUserPreferences, (userPreferences) => userPreferences?.language);

export const getRetailers = createSelector(getUserContext, ({ retailers }) => retailers);

export const isAuth = createSelector(getUserSlice, (user) => user.isAuthenticated);

export const getHasCustomerSupport = createSelector(getUserAccessRights, hasCustomerSupport);

export const getHasFileImportAdministration = createSelector(getUserAccessRights, hasFileImportAdministration);

export const getHasCustomerCompanyAdministration = createSelector(
    getUserAccessRightsNoUndefined,
    hasCustomerCompanyAdministration,
);

export const token = createSelector(getUserSlice, (user) => user.token);

export const getWelcomeMessage = createSelector(getUserSlice, (user) => user.welcomeMessage);

export const ableToAccess = createSelector(
    getUserContext,
    (userContext) => userContext.newReporting || userContext.HasCustomerSupport || userContext.HasMedicalAdvisory,
);

export const getIsAbsenceReportAdmin = createSelector(
    getUserContext,
    (userContext) =>
        userContext.HasMedicalAdvisory || userContext.HasAbsenceAdministration || userContext.HasCustomerSupport,
);
export const getHasAbsenceFollowUp = createSelector(getUserContext, (userContext) => userContext.HasAbsenceFollowup);

export const canDeleteMedicalCertificate = createSelector(
    getUserContext,
    (userContext) => userContext.HasCustomerSupport || userContext.HasAbsenceFollowup,
);

export const canShowMedicalAppendix = createSelector(
    getUserContext,
    (userContext) => userContext.HasCustomerSupport || userContext.HasAbsenceFollowup,
);
export const getIsRehabProcessManagement = createSelector(
    getUserContext,
    (userContext) => userContext.HasRehabDepartmentManagement || userContext.HasCustomerSupport,
);

export const getHasAbsenceStatistics = createSelector(
    getUserContext,
    (userContext) => userContext.HasAbsenceStatistics || userContext.HasCustomerSupport,
);

export const getHasMedicalAdvisory = createSelector(getUserContext, (userContext) => userContext.HasMedicalAdvisory);

export const getUserRetailer = createSelector(getUserContext, (userContext) =>
    userContext.activeRetailer === "Avonova" ||
    userContext.activeRetailer === "Feelgood" ||
    userContext.activeRetailer === "Falck"
        ? userContext.activeRetailer
        : "Medhelp",
);

export const getUserRetailers = createSelector(getUserContext, (userContext) => userContext.retailers);

export const getUserAccountId = createSelector(getUserContext, (userContext) => userContext.userAccount?.id);

export const getMedicalOrCustomOrAdminAccess = createSelector(
    getUserContext,
    (userContext) =>
        userContext.HasAbsenceAdministration || userContext.HasCustomerSupport || userContext.HasMedicalAdvisory,
);

export const getCustomerSupportOrAdminAccess = createSelector(
    getUserContext,
    (userContext) => userContext.HasAbsenceAdministration || userContext.HasCustomerSupport,
);

export const getAbsenceAdministrationAccess = createSelector(
    getUserContext,
    (userContext) => userContext.HasAbsenceAdministration,
);

export const getCustomerSupportAccess = createSelector(getUserContext, (userContext) => userContext.HasCustomerSupport);

export const getUserFollowUpRights = createSelector(
    getUserContext,
    (userContext) => userContext.HasAbsenceFollowup || userContext.HasCustomerSupport,
);

export const getUserCustomerOrganizationAdministrationRights = createSelector(
    getUserContext,
    (userContext) => userContext.HasCustomerOrganizationAdministration,
);

export const getCompaniesWithAbsenceView = createSelector(getUserContext, (userContext) =>
    userContext.userAccount?.accessRights
        ?.filter(
            (aar: AccountAccessRightViewModel) => aar.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceView,
        )
        .map((aar: AccountAccessRightViewModel) => {
            return aar.companyId;
        }),
);

export const getCompaniesWithAbsenceFollowUp = createSelector(getUserContext, (userContext) =>
    userContext.userAccount?.accessRights
        ?.filter(
            (aar: AccountAccessRightViewModel) =>
                aar.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceFollowup,
        )
        .map((aar: AccountAccessRightViewModel) => {
            return aar.companyId;
        }),
);
export const getUserCompanyEmployment = createSelector(getUserContext, (userContext) => userContext.companyEmployment);

export const getUserName = createSelector(getUserContext, (userContext) => userContext.user?.firstName);

export const getUserAccountCompanyId = createSelector(getUserAccount, (userAccount) => userAccount?.companyId);

export const getUserAccountCustomerId = createSelector(getUserAccount, (userAccount) => userAccount?.customerId);

export const getUserCountryCode = createSelector(getUserContext, (userContext) => userContext.user?.countryCode);
export const getRegion = createSelector(getUserContext, (userContext) => {
    const region = userContext.companyEmployment?.region;
    const countryCode = userContext.user?.countryCode ?? "SE";
    const regionNames: { [key: string]: string } = {
        SE: "Sweden",
        DK: "Denmark",
        NO: "Norway",
    };

    if (region !== null) {
        return region;
    } else {
        return regionNames[countryCode];
    }
});

export type CustomAccessRightMatcher = (accessRightType: IAccessRightTypeEnum[], accessType: AccessType) => boolean;

export const selectUserRights = (fn: (accessRights: Array<AccountAccessRightViewModel>) => CustomAccessRightMatcher) =>
    createSelector(getUserAccessRightsNoUndefined, fn);

export const hasGivenAccessRights: (
    rights: IAccessRightData[] | readonly IAccessRightData[],
) => (state: RootState) => boolean = (rights) =>
    createSelector(getUserAccessRightsNoUndefined, (accessRights) =>
        findAccessRightsBasedOnGivenRights(rights, accessRights),
    );
export const hasOnlyAbsenceStatisticsFollowUpRights = createSelector(getUserAccessRights, (accessRights) => {
    if (accessRights) {
        return hasAbsenceStatistics(accessRights) && !hasAbsenceFollowup(accessRights);
    }
    return false;
});

export const getUserFullName = createSelector(getUserContext, (userContext) => userContext.user?.name);

export const getUserPrimaryCompany = createSelector(
    getUserContext,
    (userContext) => userContext.companyEmployment?.primaryCompany,
);

export const getUserEmploymentId = createSelector(getUserContext, (userContext) => userContext.EmploymentGuid);

export const getCompanyId = createSelector(
    getActiveSearchCompanyId,
    getUserAccountCompanyId,
    (companyIdFromPowerBar, getUserAccountCompanyId) => companyIdFromPowerBar ?? getUserAccountCompanyId,
);

export const getCustomerId = createSelector(
    getActiveSearchCustomerId,
    getUserAccountCustomerId,
    (customerIdFromPowerBar, getUserAccountCustomerId) => customerIdFromPowerBar ?? getUserAccountCustomerId,
);

export const getCustomerProduct = createSelector(getUserContext, (userContext) => {
    return {
        product: userContext.Product,
        subcategory: userContext.Subcategory,
    };
});

export const getHasBaseProduct = createSelector(getUserContext, (userContext) => userContext.Product === "base");

export const getHasFortnoxProduct = createSelector(
    getUserContext,
    ({ Product, Subcategory }) => Product === "base" && Subcategory === "fortnox",
);

export const getActiveRetailer = createSelector(getUserContext, ({ retailers, activeRetailer }) =>
    retailers?.find(({ name }) => name === activeRetailer),
);

export const getActiveRetailerId = createSelector(getUserContext, ({ retailers, activeRetailer }) => {
    return retailers?.find((x) => x.name === activeRetailer)?.id;
});
