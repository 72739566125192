import Icon, { Icons } from "components/Icon";

import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface StatusMessageProps {
    title: string;
    text: string;
    icon: Icons;
    button?: ReactNode;
    theme?: "info" | "warning";
}

const StatusMessage = ({ title, text, icon, button, theme }: StatusMessageProps) => (
    <div
        className={twMerge(
            "flex flex-col border-l-6",
            theme === "info" ? "border-grey-700 bg-grey-100" : "border-mhred-alert3 bg-[#FFECE8]",
        )}
    >
        <div className="flex gap-4 p-8 pl-4">
            <div className={theme === "info" ? "text-grey-700" : "text-mhred-alert3"}>
                <Icon className={theme === "info" ? "stroke-grey-700" : "stroke-mhred-alert3"} size={24} icon={icon} />
            </div>
            <div className="flex flex-col gap-3">
                <div className="font-bold">{title}</div>
                <div>{text}</div>
                {button && button}
            </div>
        </div>
    </div>
);

export default StatusMessage;
